.Ji3VkW_sticky-banner {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.Ji3VkW_sticky-banner img {
  width: 100%;
}

.Ji3VkW_banner {
  padding-left: 145px;
  display: flex;
  position: relative;
}

.Ji3VkW_banner h1 {
  color: var(--Schemes-On-Primary, #fff);
  margin-top: 77px;
  font-family: Outfit;
  font-size: 87px;
  font-style: normal;
  font-weight: 700;
  line-height: 110px;
}

.Ji3VkW_banner p {
  color: #000;
  white-space: nowrap;
  margin-top: 84px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.Ji3VkW_banner .Ji3VkW_right {
  position: absolute;
  right: 0;
}

.Ji3VkW_banner img {
  width: 774px;
}

.Ji3VkW_key-features {
  margin-top: 287px;
  padding: 0 187.5px 379px;
  position: relative;
}

.Ji3VkW_key-features .Ji3VkW_floating-dot-1 {
  background-color: #ffba924a;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 84px;
  left: 246px;
}

.Ji3VkW_floating-feature-image {
  z-index: -1;
  position: absolute;
  top: 363.5px;
  right: 0;
}

.Ji3VkW_key-features h2 {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Ji3VkW_key-features .Ji3VkW_boxes {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 112px 59px;
  margin-top: 167px;
  display: grid;
}

.Ji3VkW_key-features .Ji3VkW_box {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  background: #f5f5f5;
  border-radius: 43px;
  padding: 42px 27px 83px 40px;
}

.Ji3VkW_key-features .Ji3VkW_box.Ji3VkW_primary {
  background: radial-gradient(327.94% 106.08% at 88.32% 46.24%, #ffbb92 0%, #ff9a8e 100%);
}

.Ji3VkW_key-features .Ji3VkW_box .Ji3VkW_box-head {
  align-items: center;
  gap: 15px;
  display: flex;
}

.Ji3VkW_key-features .Ji3VkW_box .Ji3VkW_box-head h3 {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Ji3VkW_key-features .Ji3VkW_box .Ji3VkW_box-content {
  color: #000;
  text-align: center;
  margin-top: 32px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Ji3VkW_how-it-works {
  position: relative;
}

.Ji3VkW_how-it-works .Ji3VkW_heading {
  background: #ffba924a;
  padding: 45.25px 0 45.25px 850px;
}

.Ji3VkW_how-it-works .Ji3VkW_heading h2 {
  color: #000;
  font-family: Outfit;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Ji3VkW_how-it-works .Ji3VkW_contents {
  margin-top: 77px;
  padding-left: 850px;
}

.Ji3VkW_how-it-works .Ji3VkW_contents .Ji3VkW_list {
  flex-direction: column;
  gap: 31px;
  display: flex;
}

.Ji3VkW_how-it-works .Ji3VkW_contents .Ji3VkW_item {
  align-items: center;
  gap: 59px;
  display: flex;
}

.Ji3VkW_how-it-works .Ji3VkW_contents .Ji3VkW_item .Ji3VkW_point {
  color: #000;
  font-family: Outfit;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Ji3VkW_how-it-works .Ji3VkW_floating-img {
  position: absolute;
  top: 0;
  left: 0;
}

.Ji3VkW_brands {
  background-color: #fff;
  align-items: center;
  gap: 56px;
  margin-top: 388px;
  padding: 114px 62px 136px 117px;
  display: flex;
  position: relative;
}

.Ji3VkW_brands .Ji3VkW_left {
  flex: 1;
}

.Ji3VkW_brands .Ji3VkW_tagline {
  color: #f28f02;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.Ji3VkW_brands h2 {
  color: #000;
  margin-top: 17px;
  font-family: Outfit;
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%;
}

.Ji3VkW_brands p {
  color: #000;
  max-width: 574px;
  margin-top: 36px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

.Ji3VkW_customer-voices {
  flex-direction: column;
  padding-top: 231px;
  padding-bottom: 208px;
  display: flex;
}

.Ji3VkW_customer-voices .Ji3VkW_title {
  flex-direction: column;
  max-width: 743px;
  height: 395px;
  padding: 0 136px 0 108px;
  display: flex;
  position: relative;
}

.Ji3VkW_customer-voices .Ji3VkW_title img {
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.Ji3VkW_customer-voices .Ji3VkW_title h2 {
  color: #000;
  margin-top: 74px;
  font-family: Outfit;
  font-size: 65px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.Ji3VkW_customer-voices .Ji3VkW_title div {
  color: #000;
  text-align: center;
  width: 490px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

.Ji3VkW_customer-voices .Ji3VkW_reviews {
  flex-direction: column;
  gap: 158px;
  margin-top: 97px;
  display: flex;
}

.Ji3VkW_how-it-works .Ji3VkW_floating-dot-1 {
  background-color: #ffba924a;
  border-radius: 100%;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 195.31px;
  left: 674px;
}

.Ji3VkW_how-it-works .Ji3VkW_floating-dot-2 {
  background-color: #ffba924a;
  border-radius: 100%;
  flex-shrink: 0;
  width: 39px;
  height: 39px;
  position: absolute;
  top: 481.31px;
  left: 654px;
}

.Ji3VkW_how-it-works .Ji3VkW_floating-dot-3 {
  background-color: #ffba924a;
  border-radius: 100%;
  flex-shrink: 0;
  width: 39px;
  height: 39px;
  position: absolute;
  top: 642.31px;
  left: 453px;
}

.Ji3VkW_how-it-works .Ji3VkW_floating-dot-4 {
  background-color: #ffba924a;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 222.31px;
  right: 182px;
}

.Ji3VkW_splide-nav-btn {
  width: 47.811px !important;
  height: 49.654px !important;
  padding: 0 !important;
}

.Ji3VkW_splide-nav-btn svg {
  width: 47.811px !important;
  height: 49.654px !important;
  transform: scaleX(1) !important;
}

._GNswq_review {
  width: 770px;
  margin-top: 75px;
  padding-left: 241px;
  display: flex;
  position: relative;
}

._GNswq_review:last-child {
  margin-bottom: 75px;
}

._GNswq_review-right {
  align-self: flex-end;
  margin-right: 50px;
}

._GNswq_review ._GNswq_user-details {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: absolute;
  left: 0;
}

._GNswq_review ._GNswq_user-avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100% 100% 100% 0;
  flex-shrink: 0;
  width: 213px;
  height: 213px;
  position: absolute;
  top: -49px;
  left: -106px;
}

._GNswq_review ._GNswq_user-details {
  text-align: center;
  background: #edf6ff;
  flex-direction: column;
  min-width: 200px;
  padding: 5px 20px;
  display: flex;
  position: absolute;
  top: 164px;
  left: 0;
  transform: translateX(calc(36px - 100%));
}

._GNswq_review ._GNswq_user-name {
  color: #000;
  text-align: center;
  width: 100%;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 153.562%;
}

._GNswq_review ._GNswq_user-company {
  color: #000;
  text-align: center;
  width: 100%;
  max-width: 186px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

._GNswq_review ._GNswq_inner-container {
  width: 406px;
  min-height: 360px;
  max-height: 500px;
  padding: 106px 42px 76px 116px;
  position: relative;
}

._GNswq_review ._GNswq_inner-container ._GNswq_content {
  color: #000;
  line-clamp: 3;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  display: -webkit-box;
  overflow: hidden;
}

._GNswq_review ._GNswq_quotation-mark {
  background-image: url("f200954fad573c99d2abe5b05c871ba0.d9fb240e.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  width: 68.325px;
  height: 68.325px;
  position: absolute;
  top: -30px;
  left: 107px;
  transform: rotate(-178.797deg);
}

._GNswq_review ._GNswq_review-stars {
  background: #edf6ff;
  border-radius: 0 25px 25px 0;
  gap: 4px;
  padding: 11.5px 14px;
  display: flex;
  position: absolute;
  top: 7px;
  right: -113px;
}

._GNswq_review ._GNswq_slant-background {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
  z-index: -1;
  background-color: #edf6ff;
  border-radius: 50px 50px 100% 100%;
  flex-shrink: 0;
  position: absolute;
  inset: 0;
  transform: rotate(-5deg);
}

.aSFY0q_topnav {
  justify-content: space-between;
  align-items: center;
  padding: 24px 145px;
  display: flex;
}

.aSFY0q_topnav .aSFY0q_logo img {
  width: 106px;
}

.aSFY0q_topnav .aSFY0q_links {
  align-items: center;
  gap: 150px;
  display: flex;
}

.aSFY0q_topnav .aSFY0q_links a {
  color: #000;
  cursor: pointer;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.aSFY0q_topnav .aSFY0q_avatar-link {
  align-items: center;
  display: flex;
}

.aSFY0q_topnav .aSFY0q_avatar-img {
  background-size: 60px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.BOBUQq_footer {
  background: #fff;
  padding: 55px 210px 98px;
  position: relative;
}

.BOBUQq_footer-floater {
  background: linear-gradient(90deg, #ff948d 0%, #ffbd92 100%);
  justify-content: space-between;
  align-items: center;
  gap: 26px;
  padding: 24px 33px;
  display: flex;
  position: absolute;
  top: -44.25px;
  left: 210px;
  right: 210px;
}

.BOBUQq_footer .BOBUQq_footer-floater h4 {
  color: #fff;
  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.BOBUQq_footer .BOBUQq_footer-floater button {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  background: #fff;
  border: 1px solid #f28f02;
  border-radius: 12px;
  padding: 10px 34px;
}

.BOBUQq_footer .BOBUQq_footer-columns {
  justify-content: space-between;
  display: flex;
}

.BOBUQq_footer .BOBUQq_footer-column {
  flex-direction: column;
  display: flex;
}

.BOBUQq_footer .BOBUQq_footer-column h5 {
  color: #000;
  padding: 36px 0;
  font-family: Josefin Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.BOBUQq_footer .BOBUQq_footer-column .BOBUQq_links {
  flex-direction: column;
  gap: 22px;
  margin-top: 17px;
  display: flex;
}

.BOBUQq_footer .BOBUQq_connect-column .BOBUQq_links {
  align-items: center;
  gap: 7px;
}

.BOBUQq_footer .BOBUQq_footer-column .BOBUQq_links a {
  color: #000;
  font-family: Josefin Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BOBUQq_footer .BOBUQq_footer-column .BOBUQq_links a img {
  width: 55px;
}

.BOBUQq_footer .BOBUQq_about-column img {
  width: 134px;
}

.BOBUQq_footer .BOBUQq_about-column div {
  color: #000;
  margin-top: 38px;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.BOBUQq_copyright {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.BOBUQq_copyright div {
  text-align: center;
  color: #000;
  background: linear-gradient(90deg, #ff958d 0%, #ffbc92 100%);
  border-radius: 200% 200% 0 0;
  width: 100%;
  padding: 22px 0 66px;
  font-family: Josefin Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: auto;
  animation: 1s linear infinite splide-loading;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__arrow {
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow svg {
  fill: #000;
  width: 1.2em;
  height: 1.2em;
}

.splide__arrow:hover:not(:disabled) {
  opacity: .9;
}

.splide__arrow:disabled {
  opacity: .3;
}

.splide__arrow:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__pagination {
  z-index: 1;
  padding: 0 1em;
  position: absolute;
  bottom: .5em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  opacity: .7;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 3px;
  padding: 0;
  transition: transform .2s linear;
  display: inline-block;
  position: relative;
}

.splide__pagination__page.is-active {
  z-index: 1;
  background: #fff;
  transform: scale(1.4);
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
}

.splide__pagination__page:focus-visible, .splide.is-focus-in .splide__pagination__page:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__progress__bar {
  background: #ccc;
  height: 3px;
}

.splide__slide {
  -webkit-tap-highlight-color: #0000;
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset: -3px) {
  .splide__slide:focus-visible {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #0bf;
  }
}

@supports (outline-offset: -3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline-offset: -3px;
    outline: 3px solid #0bf;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #0bf;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #0bf;
  }
}

.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible, .splide.is-focus-in .splide__toggle:focus {
  outline-offset: 3px;
  outline: 3px solid #0bf;
}

.splide__track--nav > .splide__list > .splide__slide {
  cursor: pointer;
  border: 3px solid #0000;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  border: 3px solid #000;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  inset: 0 .5em 0 auto;
}

.xW7L_W_sticky-banner {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.xW7L_W_sticky-banner img {
  width: 100%;
}

.xW7L_W_banner {
  margin-top: 51px;
  padding-left: 145px;
  display: flex;
  position: relative;
}

.xW7L_W_banner .xW7L_W_floating-dot-1 {
  z-index: -1;
  background-color: #ffbe8d4f;
  border-radius: 100%;
  width: 86px;
  height: 86px;
  position: absolute;
  top: 20px;
  left: 80px;
}

.xW7L_W_banner .xW7L_W_floating-dot-2 {
  z-index: -1;
  background-color: #ffbe8d4f;
  border-radius: 100%;
  width: 266px;
  height: 266px;
  position: absolute;
  top: 238px;
  left: -170px;
}

.xW7L_W_banner h1 {
  color: #fff;
  margin-top: 54px;
  font-family: Josefin Sans;
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.xW7L_W_banner h1 span {
  color: #000;
  font-family: Josefin Sans;
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.xW7L_W_banner p {
  color: #000;
  white-space: nowrap;
  margin-top: 43px;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.xW7L_W_banner .xW7L_W_right {
  position: absolute;
  right: 0;
}

.xW7L_W_banner img {
  width: 677px;
}

.xW7L_W_send-message {
  max-width: 784px;
  margin: auto;
  padding-top: 320px;
  padding-bottom: 224px;
}

.xW7L_W_send-message h2 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.xW7L_W_send-message form {
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 56px 30px;
  margin-top: 61px;
  display: flex;
}

.xW7L_W_send-message form input, .xW7L_W_send-message form textarea {
  color: #00000069;
  resize: none;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  background: #fff;
  border: none;
  border-radius: 0 30px 0 0;
  outline: 0;
  flex: 1;
  padding: 20px 17px;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.xW7L_W_send-message form .xW7L_W_block-input {
  flex: 0 0 100%;
}

.xW7L_W_send-message form button {
  color: #fff;
  background-color: #0000;
  background-image: url("Rectangle 3368.741b6c27.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 0;
  padding: 37px 36px 26px 118px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.xW7L_W_send-message .xW7L_W_for-queries {
  justify-content: space-between;
  align-items: center;
  margin-top: 159px;
  display: flex;
}

.xW7L_W_send-message .xW7L_W_for-queries h2 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.xW7L_W_send-message .xW7L_W_for-queries div {
  align-items: center;
  gap: 23px;
  display: flex;
}

.xW7L_W_send-message .xW7L_W_for-queries span {
  color: #000;
  font-family: Josefin Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.KwnAka_sticky-banner {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.KwnAka_sticky-banner img {
  width: 100%;
}

.KwnAka_banner {
  margin-top: 55.6px;
  padding-left: 145px;
  display: flex;
  position: relative;
}

.KwnAka_banner .KwnAka_floating-dot-1 {
  z-index: -1;
  background-color: #ffbe8d4f;
  border-radius: 100%;
  width: 86px;
  height: 86px;
  position: absolute;
  top: 80px;
  left: 99px;
}

.KwnAka_banner .KwnAka_floating-dot-2 {
  z-index: -1;
  background-color: #ffbe8d4f;
  border-radius: 100%;
  width: 266px;
  height: 266px;
  position: absolute;
  top: 338px;
  left: -143px;
}

.KwnAka_banner h1 {
  color: #000;
  margin-top: 123px;
  font-family: Josefin Sans;
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.KwnAka_banner h1 span {
  color: #000;
  font-family: Josefin Sans;
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.KwnAka_banner p {
  color: #000;
  width: 458.588px;
  margin-top: 71px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.KwnAka_banner .KwnAka_ellipse {
  color: #000;
  background: url("Ellipse 123.b1a60433.png") no-repeat;
  border-width: 0;
  border-radius: 269px;
  flex-shrink: 0;
  width: 269px;
  height: 62px;
  margin-top: 51.23px;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.KwnAka_banner .KwnAka_right {
  position: absolute;
  right: 0;
}

.KwnAka_banner img {
  width: 698px;
}

.KwnAka_our-mission {
  margin-top: 222px;
  padding: 0 146.75px 0 10px;
  display: flex;
  position: relative;
}

.KwnAka_our-mission .KwnAka_floated-design {
  position: absolute;
  top: 120px;
  right: 0;
}

.KwnAka_our-mission .KwnAka_left {
  margin-top: 78px;
}

.KwnAka_our-mission .KwnAka_left img {
  width: 670px;
}

.KwnAka_our-mission h2 {
  color: #000;
  text-align: center;
  font-family: Josefin Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.KwnAka_our-mission p {
  color: #000;
  text-align: center;
  margin-top: 54px;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.KwnAka_stats {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  margin-top: 253.29px;
  padding: 44px 89px;
  display: flex;
}

.KwnAka_stats .KwnAka_value {
  color: #000;
  text-align: center;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.KwnAka_stats .KwnAka_property {
  color: #000;
  text-align: center;
  margin-top: 19px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.KwnAka_why-trust-us {
  margin-top: 132px;
  margin-bottom: 348px;
  display: flex;
  position: relative;
}

.KwnAka_why-trust-us .KwnAka_floated-design {
  position: absolute;
  top: 235px;
  left: 0;
}

.KwnAka_why-trust-us .KwnAka_left {
  flex: 1;
  padding-top: 118px;
  padding-left: 183px;
  padding-right: 120.75px;
}

.KwnAka_why-trust-us h2 {
  color: #000;
  text-align: center;
  font-family: Josefin Sans;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}

.KwnAka_why-trust-us p {
  color: #000;
  text-align: center;
  margin-top: 43px;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.KwnAka_why-trust-us .KwnAka_right img {
  width: 584px;
}

.z86d7G_company {
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 83px 156.34px 50px 186px;
  display: flex;
  position: relative;
}

.z86d7G_company .z86d7G_floating-dot-1 {
  background-color: #ffbc944a;
  border-radius: 100%;
  width: 24.7px;
  height: 24.7px;
  position: absolute;
  bottom: 34px;
  left: 10px;
}

.z86d7G_company .z86d7G_floating-dot-2 {
  background-color: #ffbc944a;
  border-radius: 100%;
  width: 24.7px;
  height: 24.7px;
  position: absolute;
  top: 26px;
  left: 426px;
}

.z86d7G_company .z86d7G_floating-dot-3 {
  background-color: #ffbc944a;
  border-radius: 100%;
  width: 24.7px;
  height: 24.7px;
  position: absolute;
  bottom: 42px;
  right: 1px;
}

.z86d7G_company-logo {
  background-image: url("Ellipse 119.55888656.png");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 147px;
  display: flex;
  position: relative;
}

.z86d7G_company-logo img {
  height: calc(100% - 30px);
}

.z86d7G_company .z86d7G_top-tier {
  color: #000;
  background-image: url("Ellipse 120.6f18a797.png");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 85px 28.66px 85px 57px;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

.z86d7G_company .z86d7G_center {
  max-width: 590px;
}

.z86d7G_company .z86d7G_center h1 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_company .z86d7G_center .z86d7G_review-summary {
  justify-content: space-between;
  gap: 52px;
  margin-top: 20px;
  display: flex;
}

.z86d7G_company .z86d7G_center .z86d7G_review-rating, .z86d7G_company .z86d7G_center .z86d7G_review-count {
  color: #000;
  font-family: Josefin Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_company .z86d7G_center .z86d7G_website {
  color: #000;
  margin-top: 40px;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_company .z86d7G_center .z86d7G_website span:first-child {
  margin-right: 27px;
}

.z86d7G_company .z86d7G_center .z86d7G_website span:last-child {
  color: #000;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.z86d7G_review-box {
  background: linear-gradient(88deg, #f9ede5 -124.49%, #f5f5f5 227.99%);
  align-items: flex-start;
  gap: 182px;
  padding-top: 140px;
  padding-left: 122px;
  padding-right: 122px;
  display: flex;
}

.z86d7G_write-review {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.z86d7G_floating-write-icon {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
  background-color: #fff;
  border-radius: 100%;
  padding: 25px 19px;
  position: absolute;
  top: -6.5px;
  left: -50px;
}

.z86d7G_floating-lady {
  position: absolute;
  top: 0;
  right: -124px;
}

.z86d7G_floating-lady img {
  height: 372px;
}

.z86d7G_write-review .z86d7G_heading-box {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
  background-color: #fff;
  justify-content: space-between;
  padding: 26.5px 19.32px 26.5px 73px;
  display: flex;
}

.z86d7G_write-review .z86d7G_heading-box h2 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_write-review .z86d7G_heading-box div {
  cursor: pointer;
  gap: 10px;
  display: flex;
}

.z86d7G_write-review input {
  color: #000;
  border-width: 0;
  outline: 0;
  margin-top: 46px;
  padding: 34px 46px;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_write-review textarea {
  color: #000;
  resize: none;
  border-width: 0;
  outline: 0;
  margin-top: 46px;
  padding: 34px 46px;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_write-review button {
  color: #000;
  opacity: .5;
  background-color: #0000;
  background-image: url("Rectangle 3368 (1).e91d500e.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 0;
  align-self: flex-end;
  margin-top: 11px;
  padding: 40px 23px 23px 84px;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_review-stats {
  background: #fff;
  border-radius: 0 0 25px 25px;
  padding: 24px 21.5px 26.94px;
  position: relative;
}

.z86d7G_review-stats h3 {
  color: #000;
  text-align: center;
  margin-bottom: 40.83px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_review-stats .z86d7G_review-rows {
  flex-direction: column;
  gap: 31px;
  display: flex;
}

.z86d7G_review-stats .z86d7G_review-row {
  align-items: center;
  gap: 35px;
  display: flex;
}

.z86d7G_review-stats .z86d7G_review-row .z86d7G_review-number {
  color: #000;
  width: 15.63px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_review-stats .z86d7G_review-row .z86d7G_review-stars {
  gap: 10px;
  display: flex;
}

.z86d7G_review-stats .z86d7G_review-row .z86d7G_review-percent {
  color: #000;
  width: 42.8px;
  font-family: Josefin Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.z86d7G_review-stats .z86d7G_floating-pin {
  position: absolute;
  top: -43px;
  left: calc(50% - 25px);
}

.z86d7G_reviews-container {
  background: linear-gradient(88deg, #f9ede5 -124.49%, #f5f5f5 227.99%);
  gap: 78px;
  padding: 115px 122px 225px;
  display: flex;
}

.z86d7G_reviews-container .z86d7G_filter-box {
  color: #000;
  background: #fff;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 25px 33px;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: flex;
}

.z86d7G_filter {
  align-items: center;
  gap: 39px;
  display: flex;
}

.z86d7G_sort {
  align-items: center;
  gap: 6px;
  display: flex;
}

.z86d7G_review-list {
  flex-direction: column;
  gap: 191.16px;
  margin-top: 165.25px;
  display: flex;
}

.z86d7G_about-and-faq {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.z86d7G_about-heading {
  color: #000;
  text-align: center;
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400) inset;
  background: linear-gradient(90deg, #ffbd9266 0%, #ff948d66 100%);
  border-radius: 200%;
  margin-bottom: 25px;
  padding: 19px 28px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.z86d7G_about-container {
  margin-bottom: 172px;
  padding: 32px;
  position: relative;
}

.z86d7G_about-half-circle-con {
  width: 100%;
  height: calc(50% + 10px);
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.z86d7G_about-half-circle-con div {
  border: 10px solid #ff8a8c40;
  border-radius: 100%;
  width: calc(100% - 10px);
  height: 200%;
  margin: 5px;
}

.z86d7G_about-half-circle-con:before {
  content: " ";
  background-color: #ff8a8c;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(100% - 20px);
  left: 1px;
}

.z86d7G_about-half-circle-con:after {
  content: " ";
  background-color: #ff8a8c;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(100% - 20px);
  right: 1px;
}

.z86d7G_about {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  background: #fff;
  border-radius: 391px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 391px;
  height: 391px;
  padding-bottom: 20px;
  display: flex;
}

.z86d7G_about > div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.z86d7G_about .z86d7G_icon {
  background-image: url("ef8de7d11ced2e2b6f04b8b4934c6f30.702b90ef.png");
  background-size: cover;
  flex-shrink: 0;
  width: 78px;
  height: 78px;
}

.z86d7G_about .z86d7G_about-text {
  color: #000;
  text-align: center;
  line-clamp: 3;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  padding: 0 45px;
  font-family: Josefin Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  display: -webkit-box;
  overflow: hidden;
}

.z86d7G_about h4 {
  color: #000;
  margin-top: 21px;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.z86d7G_about .z86d7G_contact-text {
  color: #000;
  text-align: center;
  margin-top: 6px;
  padding: 0 94px;
  font-family: Josefin Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

.z86d7G_faq {
  position: relative;
}

.z86d7G_faq-box {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  z-index: 1;
  background: #fff;
  border-radius: 0 66px;
  flex-shrink: 0;
  width: 436px;
  padding: 23px 16px 55px;
  position: relative;
}

.z86d7G_faq-bottom-ellipse {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400) inset;
  z-index: 0;
  background: linear-gradient(90deg, #ffbd9266 0%, #ff948d66 100%);
  border-radius: 100%;
  width: 190px;
  height: 147px;
  position: absolute;
  bottom: -77px;
  right: -67px;
}

.z86d7G_faq h3 {
  color: #000;
  text-align: center;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.z86d7G_faq-items {
  flex-direction: column;
  gap: 49px;
  margin-top: 41px;
  display: flex;
}

.z86d7G_faq-item {
  color: #000;
  padding-left: 34px;
  padding-right: 41px;
  font-family: Josefin Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  position: relative;
}

.z86d7G_faq-item:before {
  content: "";
  background-color: #ff9a62;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.z86d7G_faq-item:after {
  content: "";
  border-left: 2px solid #000;
  height: calc(100% + 29px);
  position: absolute;
  top: 20px;
  left: 9px;
}

.z86d7G_faq-item:last-child:after {
  height: 0;
}

.z86d7G_pagination {
  margin-top: 149.63px;
}

.eIzPRa_review-details {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.eIzPRa_user-avatar {
  z-index: 1;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
  position: absolute;
  top: -48.75px;
  left: -33.21px;
}

.eIzPRa_user-avatar .eIzPRa_bg-icon {
  background-image: url("Ellipse 128.8813d415.png");
  background-position: contain;
  z-index: -1;
  background-repeat: no-repeat;
  width: 146px;
  height: 146px;
  position: absolute;
  top: 0;
  left: 0;
}

.eIzPRa_user-avatar .eIzPRa_avatar-img {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 114px;
  flex-shrink: 0;
  width: 114px;
  height: 114px;
}

.eIzPRa_name-and-star {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  background: #fff;
  border-radius: 50px 51px 50px 50px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 35px 5px 152px;
  display: flex;
}

.eIzPRa_name-and-star h3 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.eIzPRa_name-and-star .eIzPRa_stars {
  gap: 12.2px;
  display: flex;
}

.eIzPRa_date-and-count {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-200) var(--sds-color-black-400);
  color: #000;
  background: #fff;
  border-radius: 50px 51px 50px 50px;
  gap: 56px;
  margin-top: 4px;
  margin-left: 66px;
  padding: 5px 24px;
  font-family: Josefin Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  display: flex;
}

.eIzPRa_review-content {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-400) var(--sds-size-depth-negative-200) var(--sds-color-black-200), var(--sds-size-depth-0) var(--sds-size-depth-100) var(--sds-size-depth-100) var(--sds-size-depth-negative-100) var(--sds-color-black-100);
  background: #fff;
  border-radius: 0 61px;
  align-items: stretch;
  gap: 19px;
  margin-top: 42px;
  padding: 24px 38px 39px 16px;
  display: flex;
  position: relative;
}

.eIzPRa_review-content .eIzPRa_line {
  border-left: 3px solid #000;
}

.eIzPRa_review-content h4 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 153.562%;
}

.eIzPRa_review-content p {
  color: #000;
  margin-top: 5.75px;
  font-family: Josefin Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

.eIzPRa_review-like {
  cursor: pointer;
  background-color: #fdcbad;
  border-radius: 100%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.JJ1xjG_paginate {
  justify-content: space-between;
  display: flex;
}

.JJ1xjG_bullets {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.JJ1xjG_bullet {
  background-color: #d9d9d9;
  border-radius: 100%;
  width: 11px;
  height: 11px;
}

.JJ1xjG_active {
  background-color: #c9c9c9;
}

.JJ1xjG_disabled {
  pointer-events: none;
}

._kSCqG_user {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-size-depth-negative-100) var(--sds-color-black-200), var(--sds-size-depth-0) var(--sds-size-depth-100) var(--sds-size-depth-100) var(--sds-size-depth-negative-100) var(--sds-color-black-100);
  background: #fff;
  align-items: center;
  padding: 54px 135px;
  display: flex;
}

._kSCqG_user ._kSCqG_avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 143px 0 112px;
  width: 228px;
  height: 292px;
  margin-right: 226px;
}

._kSCqG_user ._kSCqG_details {
  flex-direction: column;
  display: flex;
}

._kSCqG_user h1 {
  color: #000;
  font-family: Josefin Sans;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

._kSCqG_user ._kSCqG_details ._kSCqG_location {
  color: #000;
  gap: 6px;
  margin-top: 30px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

._kSCqG_user ._kSCqG_details ._kSCqG_location span {
  margin-top: 6px;
}

._kSCqG_user ._kSCqG_details ._kSCqG_reviews-count {
  color: #000;
  margin-top: 30px;
  font-family: Josefin Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

._kSCqG_contributions {
  background: linear-gradient(88deg, #f9ede5 -124.49%, #f5f5f5 227.99%);
  padding-top: 104px;
  padding-bottom: 280.76px;
}

._kSCqG_contributions h2 {
  color: #000;
  margin-bottom: 60px;
  padding-left: 111px;
  font-family: Josefin Sans;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

._kSCqG_contributions ._kSCqG_review-rows {
  flex-direction: column;
  gap: 211.76px;
  display: flex;
}

._kSCqG_review-rows ._kSCqG_empty-list {
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

._kSCqG_pagination-container {
  flex-direction: column;
  width: 100%;
  margin-top: 97px;
  display: flex;
}

._kSCqG_pagination {
  align-self: flex-end;
  width: 545px;
  margin-right: 202.55px;
}

.tQ0maa_review-row {
  padding-left: 524px;
  position: relative;
}

.tQ0maa_review-row .tQ0maa_review-company {
  color: #000;
  text-align: center;
  background: #ffbc944a;
  border-radius: 200%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 561px;
  height: 93px;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  position: absolute;
  left: 31px;
}

.tQ0maa_review-company-link {
  color: #000;
  margin-left: 5px;
  text-decoration: none;
}

.tQ0maa_review-row .tQ0maa_review {
  width: 770px;
  padding-left: 241px;
  display: flex;
  position: relative;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_user-details {
  flex-direction: column;
  align-items: flex-end;
  min-width: 200px;
  display: flex;
  position: absolute;
  left: 0;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_user-avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100% 100% 100% 0;
  flex-shrink: 0;
  width: 154.554px;
  height: 154.554px;
  position: absolute;
  top: 3.48px;
  left: -88.864px;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_user-details {
  text-align: center;
  background: #edf6ff;
  flex-direction: column;
  padding: 5px 64px;
  display: flex;
  position: absolute;
  top: 152.594px;
  left: 0;
  transform: translateX(calc(39.179px - 100%));
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_user-name {
  color: #000;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 153.562%;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_user-company {
  color: #000;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_inner-container {
  width: 406px;
  min-height: 360px;
  max-height: 500px;
  padding: 30.61px 42px 118.16px 116px;
  position: relative;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_inner-container .tQ0maa_content {
  z-index: 1;
  position: relative;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_inner-container .tQ0maa_content h3 {
  color: #000;
  text-align: center;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Josefin Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: -webkit-box;
  overflow: hidden;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_inner-container .tQ0maa_content p {
  color: #000;
  line-clamp: 3;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  margin-top: 25.37px;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  display: -webkit-box;
  overflow: hidden;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_quotation-mark {
  background-image: url("f200954fad573c99d2abe5b05c871ba0.d9fb240e.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  width: 74.366px;
  height: 74.366px;
  position: absolute;
  top: -25px;
  left: 65.69px;
  transform: rotate(-178.797deg);
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_review-date {
  color: #000;
  background: #edf6ff;
  border-radius: 0 25px 25px 0;
  padding: 14.15px 29.15px 14.15px 15.24px;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 153.562%;
  position: absolute;
  top: 21.98px;
  right: -159.383px;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_review-stars {
  background: #edf6ff;
  border-radius: 0 25px 25px 0;
  gap: 4px;
  padding: 11.5px 14px;
  display: flex;
  position: absolute;
  bottom: 28.3px;
  right: -8.71px;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_like-btn {
  cursor: pointer;
  background: #edf6ff;
  border-radius: 100%;
  gap: 4px;
  padding: 11.5px 14px;
  display: flex;
  position: absolute;
  bottom: 28.3px;
  left: 45.42px;
}

.tQ0maa_review-row .tQ0maa_review .tQ0maa_slant-background {
  box-shadow: var(--sds-size-depth-0) var(--sds-size-depth-400) var(--sds-size-depth-800) var(--sds-color-black-400);
  z-index: 0;
  background-color: #edf6ff;
  border-radius: 50px 50px 100% 100%;
  flex-shrink: 0;
  position: absolute;
  inset: 0;
  transform: rotate(-5deg);
}

.Zxpo0W_login-form {
  background: #d3d3d3 url("d3892778b951dc89d2a44d20f3c7467e.14f36aa7.jpg") 50% / cover no-repeat;
  max-width: 546px;
  margin: 200px auto;
  padding: 55px 66px;
}

.Zxpo0W_login-form form {
  backdrop-filter: blur(calc(var(--sds-size-blur-100) / 2));
  background: #d9d9d987;
  border-radius: 46px;
  flex-direction: column;
  padding: 84px 33px 0;
  display: flex;
}

.Zxpo0W_login-form form .Zxpo0W_form-group {
  position: relative;
}

.Zxpo0W_form-group .Zxpo0W_icon {
  background: #d9d9d9;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 53px;
  display: flex;
  position: absolute;
  top: 0;
}

.Zxpo0W_login-form form input {
  color: #fff;
  background: #d9d9d936;
  border: none;
  border-radius: 0 30px 0 0;
  outline: 0;
  width: 100%;
  padding: 14px 14px 14px 75px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Zxpo0W_login-form form input[type="email"] {
  margin-bottom: 45px;
}

.Zxpo0W_forgot-password {
  justify-content: flex-end;
  margin-top: 9px;
  display: flex;
}

.Zxpo0W_forgot-password a {
  color: #fff;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Zxpo0W_login-now {
  justify-content: center;
  margin-top: 76px;
  display: flex;
}

.Zxpo0W_login-now button {
  color: #fff;
  background: #ffffff36;
  border: none;
  border-radius: 30px 30px 0 0;
  padding: 12px 52px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.PX20PW_login-form {
  background: #d3d3d3 url("d3892778b951dc89d2a44d20f3c7467e.14f36aa7.jpg") 50% / cover no-repeat;
  max-width: 546px;
  margin: 200px auto;
  padding: 55px 66px;
}

.PX20PW_login-form form {
  backdrop-filter: blur(calc(var(--sds-size-blur-100) / 2));
  background: #d9d9d987;
  border-radius: 46px;
  flex-direction: column;
  padding: 84px 33px 0;
  display: flex;
}

.PX20PW_login-form form .PX20PW_form-group {
  margin-bottom: 45px;
  position: relative;
}

.PX20PW_form-group .PX20PW_icon {
  background: #d9d9d9;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 53px;
  display: flex;
  position: absolute;
  top: 0;
}

.PX20PW_login-form form input {
  color: #fff;
  background: #d9d9d936;
  border: none;
  border-radius: 0 30px 0 0;
  outline: 0;
  width: 100%;
  padding: 14px 14px 14px 75px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.PX20PW_forgot-password {
  justify-content: flex-end;
  margin-top: 9px;
  display: flex;
}

.PX20PW_forgot-password a {
  color: #fff;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.PX20PW_login-now {
  justify-content: center;
  margin-top: 31px;
  display: flex;
}

.PX20PW_login-now button {
  color: #fff;
  background: #ffffff36;
  border: none;
  border-radius: 30px 30px 0 0;
  padding: 12px 52px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.KAXV0W_login-form {
  background: #d3d3d3 url("d3892778b951dc89d2a44d20f3c7467e.14f36aa7.jpg") 50% / cover no-repeat;
  max-width: 546px;
  margin: 200px auto;
  padding: 55px 66px;
}

.KAXV0W_login-form form {
  backdrop-filter: blur(calc(var(--sds-size-blur-100) / 2));
  background: #d9d9d987;
  border-radius: 46px;
  flex-direction: column;
  padding: 84px 33px 0;
  display: flex;
}

.KAXV0W_login-form form .KAXV0W_form-group {
  position: relative;
}

.KAXV0W_form-group .KAXV0W_icon {
  background: #d9d9d9;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 53px;
  display: flex;
  position: absolute;
  top: 0;
}

.KAXV0W_login-form form input {
  color: #fff;
  background: #d9d9d936;
  border: none;
  border-radius: 0 30px 0 0;
  outline: 0;
  width: 100%;
  padding: 14px 14px 14px 75px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.KAXV0W_login-now {
  justify-content: center;
  margin-top: 76px;
  display: flex;
}

.KAXV0W_login-now button {
  color: #fff;
  background: #ffffff36;
  border: none;
  border-radius: 30px 30px 0 0;
  padding: 12px 52px;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: .2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    left: env(safe-area-inset-left);
    width: 100vw;
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}

.Toastify__toast {
  --y: 0;
  -ms-touch-action: none;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  border-radius: var(--toastify-toast-bd-radius);
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  z-index: 0;
  direction: ltr;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px #0000001a;
}

.Toastify__toast--stacked {
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  width: 100%;
  transition: transform .3s;
  position: absolute;
}

.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity .1s;
}

.Toastify__toast--stacked[data-collapsed="false"] {
  overflow: visible;
}

.Toastify__toast--stacked[data-collapsed="true"]:not(:last-child) > * {
  opacity: 0;
}

.Toastify__toast--stacked:after {
  content: "";
  height: calc(var(--g) * 1px);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.Toastify__toast--stacked[data-pos="top"] {
  top: 0;
}

.Toastify__toast--stacked[data-pos="bot"] {
  bottom: 0;
}

.Toastify__toast--stacked[data-pos="bot"].Toastify__toast--stacked:before {
  transform-origin: top;
}

.Toastify__toast--stacked[data-pos="top"].Toastify__toast--stacked:before {
  transform-origin: bottom;
}

.Toastify__toast--stacked:before {
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(3);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}

.Toastify__progress-bar--wrp {
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--wrp[data-hidden="true"] {
  opacity: 0;
}

.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }

  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.67880912.css.map */
